import { Outlet, type ShouldRevalidateFunction } from '@remix-run/react';
import { type DataFunctionArgs } from '@remix-run/server-runtime';
import { typedjson, useTypedLoaderData } from 'remix-typedjson';
import { getPublicURLS } from '~/common/constants/urls';
import { PortalLayout } from '~/common/layouts';
import { MainLayout } from '~/common/layouts/MainLayout';
import { getFlags } from '~/common/utils/posthog';
import { getUserById } from '~/modules/users/user.server';
import {
    getUserId,
    requireAvalonAccess,
    requireUserId,
} from '~/session.server';

export const loader = async ({ request }: DataFunctionArgs) => {
    const pathname = new URL(request.url).pathname;
    let userId = await getUserId(request);
    const publicURLS = getPublicURLS();
    const flags = await getFlags();

    if (publicURLS.includes(pathname)) {
        return typedjson({ userId, user: null, isProtectedURL: false, flags });
    }

    if (!userId) {
        userId = await requireUserId(request);
    }
    await requireAvalonAccess(request);
    const user = await getUserById({ id: userId });
    return typedjson({ userId: user.id, user, isProtectedURL: true, flags });
};

export default function Layout() {
    const { user, isProtectedURL } = useTypedLoaderData<typeof loader>();

    return user && isProtectedURL ? (
        <PortalLayout user={user}>
            <Outlet />
        </PortalLayout>
    ) : (
        <MainLayout>
            <Outlet />
        </MainLayout>
    );
}

export const shouldRevalidate: ShouldRevalidateFunction = ({ currentUrl }) => {
    return true;
};
